import styled from '@emotion/styled';
import React, { useState, useEffect } from 'react';
import { Collapse, Icon, notification } from 'antd';
import { Helmet } from 'react-helmet';

import Layout from '../layout';
import Navigation from '../header/Navigation';
import { hexToRgba } from '../../utils/hexToRgba';
import { mediaQueries } from '../variables';
import { PasswordProtection } from './PasswordProtection';
import { config } from '../../config';
import { openExternalLink } from '../../utils/openExternalLink';

import downloadAppImage from '../../images/group-10.svg';
import googlePlayImage from '../../images/btn-google-play.svg';
import appStoreImage from '../../images/btn-appstore.svg';

const FullWidthContentContainer = styled.div({
  position: 'relative',
  paddingBottom: 70,
  [mediaQueries.md]: {
    paddingBottom: 25,
  },
});

const ContentContainer = styled.div({
  maxWidth: 1440,
  margin: '0 auto',
});

const ContentInnerWrapper = styled.div({
  padding: '0 135px',
  [mediaQueries.lg]: {
    padding: '0 60px',
  },
  [mediaQueries.md]: {
    padding: '0 15px',
  },
  [mediaQueries.xs]: {
    padding: '0 10px',
  },
});

const TopSectionWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  [mediaQueries.xl]: {
    flexDirection: 'column',
    justifyContent: 'center',
  },
});

const TopSectionTextsWrapper = styled.div({
  position: 'absolute',
  marginTop: 60,
  marginLeft: 135,
  [mediaQueries.xl]: {
    textAlign: 'center',
    margin: 0,
    top: 150,
  },
  [mediaQueries.md]: {
    marginTop: 130,
    position: 'initial',
    padding: '0 15px',
  },
});

const TopSectionImage = styled.img({
  width: '100%',
  [mediaQueries.xl]: {
    marginTop: 200,
  },
  [mediaQueries.md]: {
    marginTop: -50,
    zIndex: -1,
  },
});

const SectionTitleText = styled.h3({
  fontWeight: '900',
  fontSize: 32,
  marginBottom: 15,
  maxWidth: 290,
  color: '#1c4b81',
  [mediaQueries.md]: {
    fontSize: 22,
    marginTop: 15,
    maxWidth: 'initial',
  },
});

export const SectionDescriptionText = styled.h3({
  fontWeight: '400',
  fontSize: 20,
  color: '#6e8997',
  lineHeight: '36px',
  maxWidth: 420,
  [mediaQueries.md]: {
    fontSize: 16,
  },
});

export const SectionDescriptionColorfulText = styled.span({
  fontWeight: '900',
  fontSize: 20,
  color: '#2d80e2',
  lineHeight: '36px',
  [mediaQueries.md]: {
    fontSize: 16,
  },
});

export const SectionSmallDescriptionText = styled.h3({
  fontWeight: '300',
  fontSize: 14,
  marginTop: 15,
  color: '#74879c',
  lineHeight: '24px',
  fontStyle: 'oblique',
  maxWidth: 420,
  [mediaQueries.md]: {
    fontSize: 12,
  },
});

export const SectionSmallDescriptionColorfulText = styled.span({
  fontWeight: '500',
  color: '#2d80e2',
  lineHeight: '24px',
  textDecoration: 'underline',
  cursor: 'pointer',
});

const TopSectionTitleText = styled(SectionTitleText)({
  fontSize: 45,
  marginBottom: 12,
  maxWidth: 'initial',
  [mediaQueries.md]: {
    fontSize: 28,
  },
});

const TopSectionDescriptionText = styled(SectionDescriptionText)({
  maxWidth: 650,
});

const CenteredTitleText = styled(SectionTitleText)({
  textAlign: 'center',
  maxWidth: 'initial',
  fontSize: 38,
  marginTop: 100,
  [mediaQueries.md]: {
    fontSize: 28,
  },
});

const SectionWrapper = styled.div(props => ({
  marginTop: 100,
  display: 'flex',
  justifyContent: 'space-between',
  flexFlow: props.reverse ? 'row-reverse' : 'row',
  [mediaQueries.xl]: {
    flexDirection: 'column-reverse',
    justifyContent: 'center',
  },
  [mediaQueries.md]: {
    marginTop: 20,
  },
}));

const SectionTextsWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  [mediaQueries.xl]: {
    textAlign: 'center',
    alignItems: 'center',
  },
});

export const FaqPanelDescription = styled.p({
  fontSize: 18,
  fontWeight: 500,
  color: '#687284',
  marginBottom: 0,
  lineHeight: '32px',
  [mediaQueries.md]: {
    fontSize: 14,
  },
});

export const FaqPanelDescriptionColorful = styled(FaqPanelDescription)({
  color: '#2d80e2',
  fontWeight: 800,
  cursor: 'pointer',
  display: 'inline',
});

const FaqCollapse = styled(Collapse)({
  backgroundColor: 'transparent',
  marginTop: 30,

  '& .ant-collapse-item': {
    background: '#fff',
    color: '#2e3d4e',
    borderRadius: '6px !important',
    marginTop: 12,
    fontSize: 20,
    padding: '17px 0 15px',
    boxShadow: '0 6px 14px 0 rgba(179, 193, 210, 0.27)',
    border: 'solid 2px #e6ecf7',
    overflow: 'hidden',
    fontWeight: '900',
    lineHeight: '30px',
  },
  '& .anticon-down': {
    color: '#395174',
    padding: 9,
    fontSize: 12,
    borderRadius: '50%',
    backgroundColor: '#e6ecf7',
  },
  '& .ant-collapse-content-box': {
    paddingBottom: 10,
  },
});

const FaqList = styled.ul({
  listStyle: 'none',
  padding: 0,
});

const FaqUnorderedListItem = styled.li({
  borderRadius: 5,
  marginTop: 10,
  padding: '9px 18px 6px',
  backgroundColor: '#ebf1ff',
  borderLeft: '4px solid #2d80e2',
  borderBottomLeftRadius: 0,
  borderTopLeftRadius: 0,
});

const FaqUnorderedListItemTitle = styled.span({
  display: 'block',
  fontWeight: '900',
  fontSize: 18,
  color: '#2e3d4e',
  [mediaQueries.md]: {
    fontSize: 16,
  },
});

const FaqUnorderedListItemDescription = styled.span({
  fontWeight: '500',
  fontSize: 18,
  color: '#687284',
  [mediaQueries.md]: {
    fontSize: 14,
  },
});

const FaqOrderedListItem = styled(FaqUnorderedListItem)({
  borderRadius: 5,
  display: 'flex',
  alignItems: 'center',
  border: 'none',
  padding: '8px 12px',
});

const FaqOrderedListItemNumber = styled.span({
  fontWeight: '900',
  fontSize: 18,
  color: '#2d80e2',
  height: 55,
  minWidth: 55,
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  backgroundColor: hexToRgba('#a3c2ff', 0.5),
  [mediaQueries.md]: {
    fontSize: 16,
  },
});

export const FaqOrderedListItemDescription = styled.span({
  fontWeight: '500',
  fontSize: 18,
  color: '#3c4e62',
  lineHeight: '32px',
  marginLeft: 25,
  [mediaQueries.md]: {
    fontSize: 14,
  },
});

export const FaqOrderedListItemDescriptionColorful = styled.span({
  color: '#2d80e2',
  fontWeight: 800,
  cursor: 'pointer',
});

const DownloadAppSectionWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: 90,
  [mediaQueries.md]: {
    marginTop: 50,
  },
});

const DownloadAppTitle = styled.h5({
  color: '#0f2d4e',
  fontWeight: '900',
  fontSize: 35,
  marginTop: 25,
  marginBottom: 15,
  [mediaQueries.md]: {
    fontSize: 28,
    marginTop: 15,
  },
});

const DownloadAppDescription = styled.p({
  color: '#4d6580',
  fontWeight: 300,
  fontSize: 20,
  maxWidth: 500,
  marginBottom: 40,
  textAlign: 'center',
  [mediaQueries.md]: {
    fontSize: 18,
    marginBottom: 30,
  },
});

const DownloadAppStoreButtonsWrapper = styled.div({
  display: 'flex',
  [mediaQueries.md]: {
    flexDirection: 'column',
  },
});

const StoreImage = styled.img({
  height: 70,
  cursor: 'pointer',
  [mediaQueries.md]: {
    height: 60,
  },
});

const AppStoreImage = styled(StoreImage)({
  marginRight: 20,
  [mediaQueries.md]: {
    margin: '0 0 15px',
  },
});

const DownloadAppImage = styled.img({
  maxWidth: 660,
  width: '100%',
});

const BackgroundGradient = styled.div({
  position: 'absolute',
  bottom: 0,
  left: 0,
  height: 1300,
  zIndex: -1,
  width: '100vw',
  backgroundImage: 'linear-gradient(to bottom, #ffffff, #e6ecf7)',
});

const FooterWrapper = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: '55px 0',
  backgroundColor: '#273545',
  [mediaQueries.md]: {
    padding: '25px 0',
  },
});

const FooterTitle = styled.p({
  fontWeight: '900',
  fontSize: 23,
  marginBottom: 11,
  textAlign: 'center',
  color: '#fff',
});

const FooterDescription = styled(FooterTitle)({
  fontWeight: '300',
  fontSize: 18,
});

const FooterLink = styled(FooterTitle.withComponent('a'))({
  fontSize: 18,
  cursor: 'pointer',
  textDecoration: 'underline',
});

const Section = ({ title, description, smallDescription = '', image, reverse }) => (
  <SectionWrapper reverse={reverse}>
    <SectionTextsWrapper>
      <SectionTitleText>{title}</SectionTitleText>
      {typeof description === 'string' ? (
        <SectionDescriptionText>{description}</SectionDescriptionText>
      ) : (
        description
      )}

      {typeof smallDescription === 'string' ? (
        <SectionDescriptionText>{smallDescription}</SectionDescriptionText>
      ) : (
        smallDescription
      )}
    </SectionTextsWrapper>

    <img src={image} alt="sitting man" />
  </SectionWrapper>
);

const PreventIndexing = () => (
  <Helmet>
    <meta name="robots" content="noindex, nofollow, noarchive" />
  </Helmet>
);

export const InformativePage = ({ data }) => {
  const [loading, setLoading] = useState(data.passwordToEnter);
  const [passwordCorrect, setPasswordCorrect] = useState(!data.passwordToEnter);

  useEffect(() => {
    if (data.passwordToEnter) {
      const password = window.location.search.split('?')[1];
      const passwordMatch = password === data.passwordToEnter;

      setPasswordCorrect(passwordMatch);
    }

    setLoading(false);
  }, []);

  const handleSubmit = password => {
    if (password === data.passwordToEnter) {
      setPasswordCorrect(true);
    } else {
      notification.error({
        message: "Provided password isn't correct",
      });
    }
  };

  if (loading) {
    return null;
  }

  if (!passwordCorrect) {
    return (
      <>
        <PreventIndexing />
        <PasswordProtection
          companyName={data.companyName}
          image={data.passwordPageImage}
          onSubmit={handleSubmit}
        />
      </>
    );
  }

  return (
    <>
      <PreventIndexing />

      <Layout>
        <Navigation />

        <FullWidthContentContainer>
          <ContentContainer>
            <TopSectionWrapper>
              <TopSectionTextsWrapper>
                <TopSectionTitleText>{data.topSectionTitle}</TopSectionTitleText>
                <TopSectionDescriptionText>{data.topSectionDescription}</TopSectionDescriptionText>
              </TopSectionTextsWrapper>

              <TopSectionImage src={data.topSectionImage} alt="main image" />
            </TopSectionWrapper>

            <ContentInnerWrapper>
              <CenteredTitleText>With Modern Health, you’ll have access to:</CenteredTitleText>
              {data.benefits.map(benefit => (
                <Section
                  key={benefit.title}
                  title={benefit.title}
                  description={benefit.description}
                  smallDescription={benefit.smallDescription}
                  image={benefit.image}
                  reverse={benefit.reverse}
                />
              ))}

              <CenteredTitleText>Frequently Asked Questions</CenteredTitleText>
              <FaqCollapse
                bordered={false}
                expandIconPosition="right"
                expandIcon={({ isActive }) => <Icon type="down" rotate={isActive ? 180 : 0} />}
              >
                {data.faq.map(
                  ({ title, description, orderedList, unorderedList, secondDescription }) => (
                    <Collapse.Panel header={title} key={title}>
                      {description &&
                        (typeof description == 'string' ? (
                          <FaqPanelDescription>{description}</FaqPanelDescription>
                        ) : (
                          description
                        ))}

                      {unorderedList ? (
                        <FaqList>
                          {unorderedList.map(item => (
                            <FaqUnorderedListItem key={item.title}>
                              <FaqUnorderedListItemTitle>{item.title}</FaqUnorderedListItemTitle>
                              <FaqUnorderedListItemDescription>
                                {item.description}
                              </FaqUnorderedListItemDescription>
                            </FaqUnorderedListItem>
                          ))}
                        </FaqList>
                      ) : null}

                      {orderedList ? (
                        <FaqList>
                          {orderedList.map((item, i) => (
                            <FaqOrderedListItem key={item.description}>
                              <FaqOrderedListItemNumber>{i + 1}</FaqOrderedListItemNumber>
                              {typeof item.description == 'string' ? (
                                <FaqOrderedListItemDescription>
                                  {item.description}
                                </FaqOrderedListItemDescription>
                              ) : (
                                item.description
                              )}
                            </FaqOrderedListItem>
                          ))}
                        </FaqList>
                      ) : null}

                      {secondDescription &&
                        (typeof secondDescription == 'string' ? (
                          <FaqPanelDescription>{secondDescription}</FaqPanelDescription>
                        ) : (
                          secondDescription
                        ))}
                    </Collapse.Panel>
                  ),
                )}
              </FaqCollapse>

              <DownloadAppSectionWrapper>
                <DownloadAppImage src={downloadAppImage} alt="human with phone" />
                <DownloadAppTitle>Download Modern Health</DownloadAppTitle>
                <DownloadAppDescription>
                  Get in-app coach chat, more meditations and unique programs on iOS and Android.
                </DownloadAppDescription>
                <DownloadAppStoreButtonsWrapper>
                  <AppStoreImage
                    src={appStoreImage}
                    onClick={() => openExternalLink(config.appStoreUrl)}
                    alt="link to the app store"
                  />
                  <StoreImage
                    src={googlePlayImage}
                    onClick={() => openExternalLink(config.googlePlayUrl)}
                    alt="link to the google play store"
                  />
                </DownloadAppStoreButtonsWrapper>
              </DownloadAppSectionWrapper>
            </ContentInnerWrapper>
          </ContentContainer>

          <BackgroundGradient />
        </FullWidthContentContainer>

        <FooterWrapper>
          <FooterTitle>Have questions about Modern Health?</FooterTitle>
          <FooterDescription>Feel free to contact us anytime at</FooterDescription>
          <FooterLink href="mailto:info@joinmodernhealth.com">info@joinmodernhealth.com</FooterLink>
        </FooterWrapper>
      </Layout>
    </>
  );
};
