import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { Input, Button } from 'antd';

import mhLogo from '../../images/mh-logo.svg';
import { mediaQueries } from '../variables';

const Wrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
});

const StyledLogo = styled.img({
  padding: '1.5rem',
});

const HeaderWrapper = styled.div({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: '2px solid #fafafa',
  [mediaQueries.sm]: {
    flexDirection: 'column',
  },
});

const GoToText = styled.span({
  color: '#4d6580',
  height: '100%',
  display: 'block',
  padding: '1.5rem',
  fontWeight: '500',
  fontSize: 15,
  [mediaQueries.sm]: {
    display: 'none',
  },
});

const ContentWrapper = styled.div({
  padding: '200px 20px 0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [mediaQueries.sm]: {
    paddingTop: 50,
  },
});

const Title = styled.h2({
  color: '#2e3d4e',
  marginTop: 25,
  marginBottom: 8,
  fontWeight: '800',
  fontSize: 30,
  textAlign: 'center',
});

const Description = styled.h5({
  color: '#74879c',
  fontWeight: '00',
  fontSize: 16,
  textAlign: 'center',
});

const PasswordInput = styled(Input)({
  maxWidth: 380,
  color: '#7b8da2',
  fontWeight: '500',
  fontSize: 16,
  marginTop: 15,
  height: 46,
  borderRadius: 4,
  border: '1.5px solid #b3c1d2',
  textAlign: 'center',
});

const ContinueButton = styled(Button)({
  maxWidth: 380,
  marginTop: 16,
  borderRadius: 4,
  height: 48,
  fontSize: 18,
  backgroundColor: '#2d80e2',
  color: '#fff',
  boxShadow: '0 8px 12px 0 rgba(183, 186, 190, 0.5)',
  ':hover, :focus': {
    backgroundColor: '#2d80e2',
    color: '#fff',
  },
});

export const PasswordProtection = ({ onSubmit, companyName, image }) => {
  const [password, setPassword] = useState('');

  return (
    <Wrapper>
      <HeaderWrapper>
        <Link to="/">
          <StyledLogo src={mhLogo} alt="Modern Health" />
        </Link>
        <Link to="/">
          <GoToText>Go to Modern Health</GoToText>
        </Link>
      </HeaderWrapper>

      <ContentWrapper>
        <img src={image} alt={`${companyName} logo`} />
        <Title>Getting Started Guide for {companyName}</Title>
        <Description>Please enter password to get an access.</Description>
        <PasswordInput
          placeholder="Password"
          size="large"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <ContinueButton size="large" onClick={() => onSubmit(password)} block>
          Continue
        </ContinueButton>
      </ContentWrapper>
    </Wrapper>
  );
};
